import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";

// Pages import Here
import Splash from "./pages/Splash";
import Privacy from "./pages/Privacy";
import AboutUs from "./pages/AboutUs";

// Elements import Here

import Contact from "./elements/contact/Contact";
import Product from "./pages/Marketing";
import Error from "./pages/Error";

// Import Css Here
import "./assets/scss/style.scss";

const App = () => {
  return (
    <Router>
        <Routes>
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            element={<Splash/>}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/lingua-engine"}`}
            exact
            element={<Product />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/Privacy"}`}
            exact
            element={<Privacy />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/about-us"}`}
            exact
            element={<AboutUs />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            exact
            element={<Contact />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/error"}`}
            exact
            element={<Error />}
          />
        </Routes>
    </Router>
  );
};

export default App;
