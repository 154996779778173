import React from 'react'
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import FooterThree from '../common/footer/FooterThree';
import BrandTwo from '../elements/brand/BrandTwo';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";

const AboutUs = () => {
    return (
        <>
            <SEO title="About Us" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                <BreadcrumbOne
                    title="Learn more about us,<br /> our processes, people and technologies."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="About"
                />

                <div className="rwt-brand-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Our Tech Stack."
                                    title="We work with the latest Technologies."
                                    description="Our in-depth knowledge of modern technologies enable us to work with most frameworks."
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandTwo brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>


                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="What we can do for you"
                                    title="Our Servcies."
                                    description="We provide a wide range of expertise from UI design to Cloud Hosting."
                                />
                            </div>
                        </div>
                        <ServiceOne
                            serviceStyle="service__style--1 bg-color-blackest radius mt--25"
                            textAlign="text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <div className="rwt-timeline-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineTwo classVar="no-gradient" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Elements Area  */}
                <div className="rwt-counterup-area pb--100">
                    <div className="container mt_dec--30">
                        <CounterUpFour column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />
                    </div>
                </div>
                {/* End Elements Area  */}



                <FooterThree />
            </main>
        </>
    )
}

export default AboutUs;
