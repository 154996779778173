import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 34.68239503748359,
      lng: 33.04292631669182
    },
    zoom: 11
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div className="google-map-style-1">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBxkpr0IH_xP6QkI3OVuTGb2glr9n0SMsY' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={34.68239503748359}
            lng={33.04292631669182}
            text="We are here"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMapStyle;
