import React from 'react';
import { Link } from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><Link to="/">Home</Link>
            </li>
            <li className="has-droupdown"><Link to="#">Offerings</Link>
                <ul className="submenu">
                    <li><Link to="/lingua-engine">Lingua Engine <span className="ml--5 rn-badge-card">New</span></Link></li>
                    <li><Link to="#">Cora AI<span className="ml--5 rn-badge-card">Coming</span></Link></li>
                </ul>
            </li>

            <li><Link to="/about-us">About</Link></li>

            <li className="has-droupdown"><Link to="#">Blog</Link>
                <ul className="submenu">
                    <li><Link to="#">Latest <span className="ml--5 rn-badge-card">Coming</span></Link></li>
                </ul>
            </li>


            <li className="has-droupdown"><Link to="/contact">Contact</Link></li>
        </ul>
    )
}
export default Nav;
