import React from 'react';
import { FiCheck } from "react-icons/fi";
import { FiX } from "react-icons/fi";

const PricingFive = () => {
    return (
        <div className="row row--15">

            {/* Start PRicing Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5 active">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Open Source</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">€</span>
                                    <span className="price">0</span>
                                </div>
                                <span className="subtitle">EUR Per Month</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiX /> Engineering Support</li>
                                <li><FiX /> Professional Services</li>
                                <li><FiCheck /> 10 Daily Queries</li>
                                <li><FiCheck /> Up to 1 Projects</li>
                                <li><FiCheck /> API Playground</li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <a className="btn-default" href="https://lingua.arcifa-web-technologies.com/login">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

            {/* Start PRicing Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Advanced Plan</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">€</span>
                                    <span className="price">1k</span>
                                </div>
                                <span className="subtitle">EUR Per Month</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> Engineering Support</li>
                                <li><FiCheck /> Professional Services</li>
                                <li><FiCheck /> 100,000 Daily Queries</li>
                                <li><FiCheck /> Up to 10 Projects</li>
                                <li><FiCheck /> API Playground</li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <a className="btn-default" href="mailto:info@arcifa-web-technologies.com?subject=Advanced%20Enquiry">Get in touch</a>
                        </div>
                    </div>
                </div>
            </div>

                        {/* Start PRicing Table Area  */}
                        <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-5">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Enterprise Plan</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
            
                                    <span className="price">Custom</span>
                                </div>
                                <span className="subtitle">EUR Per Month</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> Engineering Support</li>
                                <li><FiCheck /> Professional Services</li>
                                <li><FiCheck /> Unlimited Queries</li>
                                <li><FiCheck /> Unlimited Projects</li>
                                <li><FiCheck /> API Playground</li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <a className="btn-default" href="mailto:info@arcifa-web-technologies.com?subject=Enterprise%20Enquiry">Get in touch</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

        </div>
    )
}
export default PricingFive;
