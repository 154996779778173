import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterThree from '../common/footer/FooterThree';
import { FiArrowDown } from "react-icons/fi";
import { ReactTyped } from "react-typed";
import ServiceOne from '../elements/service/ServiceOne';
import CircleProgress from "../elements/progressbar/CircleProgress";
import BrandTwo from '../elements/brand/BrandTwo';

const Splash = () => {
    return (
        <>
            <SEO title="Arcifa Web Technologies - Your Digital Technology Partner" />
            <main className="page-wrapper">
       
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="demo-slider-area slider-area bg-transparent slider-style-1 pb--100 pt--70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <div className="react-image mb--20">
                                        <img src="./images/demo/react-badge.png" alt="React" />
                                    </div>
                                    <h1 className="title display-two">
                                        Next-gen all-in-one <br />
                                        <span className="theme-gradient">
                                            <ReactTyped
                                                strings={[
                                                    "Consulting",
                                                    "Development",
                                                    "Implementation",
                                                ]}
                                                typeSpeed={80}
                                                backSpeed={5}
                                                backDelay={1000}
                                                loop
                                            />
                                        </span>
                                        <br />
                                        Agency.
                                    </h1>
                                    <p className="description">We help our clients succeed by creating brand identities, digital experiences, and agile development.</p>
                                    <div className="button-group">
                                        <a className="btn-default btn-large round btn-icon" href="#services">View Services <FiArrowDown /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">We are your Digital Technology Partner.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Born from passion in modern Software Engineering practices, our mission is to provide services in line with the highest industry standards. As your technology partner, we will help you achieve your goals.</p>
                                <div className="readmore-btn">
                                    <a className="btn-read-more" href="#services-2"><span>View More</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gapTop" id="services">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="What we can do for you"
                                    title="Our Servcies."
                                    description="We provide a wide range of expertise from UI design to Cloud Hosting."
                                />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <div className="rwt-progressbar-area rn-section-gap" id="services-2">
                    <div className="container">
                        <div className="row mb--25">
                            <div className="col-lg-10 offset-lg-1">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Our Expertise."
                                    title="We are specialised in Web Technologies."
                                    description="Our team is highly specialised in modern Web Technologies."
                                />
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1">
                            <CircleProgress />
                        </div>
                    </div>
                </div>

                {/* Start Tech Stack Area  */}
                <div className="rwt-brand-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Our Tech Stack."
                                    title="We work with the latest Technologies."
                                    description="Our in-depth knowledge of modern technologies enable us to work with most frameworks."
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandTwo brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                {/* Start Our Demo 
                <div className="rn-demo-area rn-section-gap" id="demo">
                    <div className="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="All Demo Here"
                                    title="Doob All Demo."
                                    description="We create a business and consulting react template with 50+ elements features. <br /> 18+ demo pages, faster loading and well documentated code."
                                />
                            </div>
                        </div>
                        <div className="row row--30">
                            {Demo.map((data, index) => (
                                <div className="col-lg-4 col-md-6 col-12 mt--40" key={index}>
                                    <div className="single-demo">
                                        <Link to={`${data.Url}`}>
                                            <div className="thumbnail">
                                                <img className="image-dark" src={`${data.Image}`} alt="Corporate Images" />
                                                <img className="image-light" src={`${data.Imagelight}`} alt="Corporate Images" />
                                                {data.badge ? <span className="label-badge">{data.badge}</span> : ''}
                                            </div>
                                        </Link>
                                        <h4 className="title"><Link to={`${data.Url}`}>{data.Title}</Link> </h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <Separator />

                {/* Start Inner Pages  
                <div className="rn-inner-pages rn-section-gap">
                    <div className="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">

                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="All Others Pages"
                                    title="All Others Pages."
                                    description="Have a nice inner pages include in doob template."

                                />
                            </div>
                        </div>

                        <div className="row">
                            {InnerPages.map((data, index) => (
                                <div className="col-lg-4 col-md-6 col-12 mt--40" key={index}>
                                    <div className="single-demo">
                                        <Link to={`${data.Url}`}>
                                            <div className="thumbnail">
                                                <img className="image-dark" src={`${data.Image}`} alt="Corporate Images" />
                                                <img className="image-light" src={`${data.Imagelight}`} alt="Corporate Images" />
                                            </div>
                                        </Link>
                                        <h4 className="title"><Link to={`${data.Url}`}>{data.Title}</Link></h4>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            */}
                {/* End Inner Pages  */}
                <FooterThree />
            </main>

        </>
    )
}

export default Splash;