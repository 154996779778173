import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import FooterThree from '../common/footer/FooterThree';
import { ReactTyped } from 'react-typed';
import { FiArrowRight } from "react-icons/fi";
import ServiceFour from '../elements/service/ServiceFour';
import Pricing from '../elements/pricing/PricingFive';

import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";


const Marketing = () => {
    return (
        <>
            <SEO title="Marketing" />
            <main className="page-wrapper">
                
     
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />


                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent variation-2 pb--100 pt--70">
                    <div className="container">
                        <div className="row" style={{    alignItems: 'center'}}>
                            <div className="col-lg-7 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-left">
                                <div className="thumbnail">
                                    <img src="./images/lingua-engine.svg" alt="lingua-engine" />
                                </div>
                                    <h1 className="title theme-gradient display-two">AI Power For Every{" "}
                                        <ReactTyped
                                            strings={[
                                                "Industry.",
                                                "Business.",
                                                "Challenge.",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <p className="description">Lingua Engine empowers our clients with a robust AI client that enhance brand identities, streamline digital experiences, and optimize operational efficiencies.</p>
                                    <div className="button-group">
                                        <a className="btn-default btn-medium btn-border round btn-icon" href="https://lingua.arcifa-web-technologies.com/login">Get Started <i className="icon"><FiArrowRight /></i></a>
                                        <a className="btn-default btn-medium btn-border round btn-icon" href="mailto:info@arcifa-web-technologies.com?subject=Enterprise%20Enquiry">Enterprise <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/code.svg" alt="Code" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Explore Lingua Engine"
                                    title="A Custom AI Engine"
                                    description="Explore the range of AI customizations of Lingua Engine, <br /> tailored to meet diverse business needs and challenges."
                                />
                            </div>
                        </div>
                        <ServiceFour 
                            serviceStyle="service__style--1 icon-circle-style with-working-process"
                            textAlign="text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Elements Area */}
                <div className="rwt-pricingtable-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Explore Our Pricing"
                                    title="Lingua Engine Plans"
                                    description="Select the perfect AI solution tailored for your business needs. <br /> Flexible pricing to scale with your growth."
                                />
                            </div>
                        </div>
                        <div className="row mt--30">
                            <div className="col-lg-8 offset-lg-2">
                                <Pricing />
                            </div>
                        </div>
                    </div>
                </div>

                {/* End Elements Area  */}
                <FooterThree />

            </main>
        </>
    )
}

export default Marketing;
