import { useState, useRef } from "react";
import { FiMenu } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import Darkmode from "./Darkmode";
import useStickyHeader from "./useStickyHeader";

const HeaderOne = ({ btnStyle, HeaderSTyle }) => {
    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow(prev => !prev);
    }


    const ref = useRef();
    let [check, setCheck] = useState(true);
    const sticky = useStickyHeader(50);
    const headerClasses = `header-default ${(sticky && check) ? 'sticky' : ''}`
    const { clientHeight } = ref;

    const checkChange = (value) => {
        setCheck(value);
    };

    return (
        <>
            <header ref={ref} className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}>
                <div className="container position-relative">
                    <div className="row align-items-center row--0">
                        <div className="col-lg-6 col-md-6 col-4">
                        <div className="row align-items-center row--0">
                            <Logo
                                image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                                image2={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                            />

                            <div className="header-left ml--5">
                                <nav className="mainmenu-nav d-none d-lg-block">
                                    <ul className="mainmenu">
                                        <li className="has-droupdown">
                                            <span>Arcifa Web Technologies</span>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-8 position-static">
                            <div className="header-right">
                                <nav className="mainmenu-nav d-none d-lg-block">
                                    <Nav />
                                </nav>

                                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                    <div className="hamberger">
                                        <button className="hamberger-button" onClick={onCanvasHandler}><FiMenu /></button>
                                    </div>
                                </div>
                                <Darkmode />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
        </>
    )
}
export default HeaderOne;